<template>
    <div :class="{'select-multiple':true, theme:theme}" >
    <b-dropdown :variant="myVariant" block @click.prevent="dropdown_click" :size="size" menu-class="w-100" no-caret
    :disabled="disabled">
      <template #button-content v-if="text">
        <b-row>
          <b-col class="text-left m-0 formated-text" style="width:23px!important;">{{ text }}</b-col>
          <b-col cols="auto" class="text-right m-0 chevron pr-50">
             <span v-if="theme"><font-awesome-icon :icon="['fas', 'angle-down']"/></span>
             <span v-else><font-awesome-icon :icon="icon"/></span>
            </b-col>
        </b-row>
      </template>
      <template #button-content v-else>
        <template>
          <b-row>
            <b-col class="text-left m-0" style="width:23px!important;">{{ placeholder ? placeholder : "-- Seleccione --" }}</b-col>
            <b-col cols="auto" class="text-right m-0 chevron pr-50">
              <span v-if="theme"><font-awesome-icon :icon="['fas', 'angle-down']" :class="variant == 'outline-danger' ? 'text-danger' : ''"/></span>
             <span v-else><font-awesome-icon :icon="icon" :class="variant == 'outline-danger' ? 'text-danger' : ''"/></span>
            </b-col>
          </b-row>
        </template>
      </template>
      <b-dropdown-form v-if="optionsNumber > 2">
        <b-row class="mb-1">
          <b-col no-gutters>
            <b-form-input v-model="filter"/>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col no-gutters>
            <b-button-group class="w-100">
              <div v-if="theme">
                <icon-button class="mb-1"  text="Marcar todos" @select="selectAll"/>
                 <icon-button text="Desmarcar Todos" @select="unselectAll"/>
              </div>
              <div v-else>
                <b-button class="word-no-break" variant="outline-primary"
                  @click="selectAll">Marcar todos
                </b-button>
                <b-button class="word-no-break" variant="outline-primary"
                          @click="unselectAll"> Desmarcar Todos
                </b-button>
              </div>
            </b-button-group>
          </b-col>
        </b-row>
      </b-dropdown-form>
      <b-dropdown-divider v-if="optionsNumber > 9"></b-dropdown-divider>
      <div class="select-list overflow-auto">
        <li role="presentation" v-for="(option, idx) in myFilteredOptions" :key="idx">
          <button role="menuitem" type="button" class="dropdown-item" :class="{ active: myActiveOptions[option[optionValue]] }"
                  @click.prevent="toggleOption(option)">
            {{ option[optionLabel] }}
            <font-awesome-icon :icon="['fa', 'check']" style="float:right"
                              v-if="myActiveOptions[option[optionValue]]"/>
          </button>
        </li>
      </div>
    </b-dropdown>
    <label  :class="{hiddenLabel:theme&&!text, floatingLabel:theme&&text}">{{label}}</label>
  </div>
</template>

<style lang="css">
  .select-multiple .btn.dropdown-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 37.79px;
  }
  .select-multiple .btn.dropdown-toggle.btn-sm {
    height: 30.68px!important;
  }
  .formated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-multiple .btn-outline-secondary:hover:not(.disabled):not(:disabled) {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-secondary {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .show > .btn-outline-secondary.dropdown-toggle {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-secondary:not(:disabled):not(.disabled):focus {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-danger:hover:not(.disabled):not(:disabled) {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-danger {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .show > .btn-outline-danger.dropdown-toggle {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-danger:not(:disabled):not(.disabled):focus {
    color: #6E6B7B;
    background: white;
  }
  .select-multiple .btn-outline-secondary{
    border: 1px solid #d8d6de!important;
  }
  .chevron .svg-inline--fa{
    height: 1.2rem!important;
    width: 1.2rem!important;
    color: #9CA0A4 !important
  }
  .dropdown .btn-group .btn {
    width: 12rem;
  }
  .dropdown-menu .dropdown-item {
    width: 100% !important;
  }
  .word-no-break {
    word-break: keep-all;
    white-space: nowrap;
  }
  .select-list {
    max-height: 270px;
  }
  .select-multiple.theme .btn-outline-secondary {
    border: 2px solid #A5A5A5!important;
  }
  .select-multiple.theme .show > .btn-outline-secondary.dropdown-toggle {
    border: 2px solid #00718C!important;
  }
  .select-multiple.theme .dropdown .btn-outline-secondary.dropdown-toggle .row .text-left.m-0.formated-text.col,
  .select-multiple.theme .dropdown .btn-outline-secondary.dropdown-toggle .row .text-left.m-0.col{
    color:  black;
    font-family: 'Poppins';
    padding-left:5px;
  }
  .select-multiple.theme .dropdown .btn-outline-secondary.dropdown-toggle .row .text-left.m-0.col{
    color:#A5A5A5;
    font-size: 13px;
  }
  .select-multiple.theme .show > .btn-outline-secondary.dropdown-toggle .row .text-left.m-0.col {
    padding-left:5px;
  }
   .select-multiple.theme  .btn.dropdown-toggle {
    width: 93%;
    height: 32px;
    padding-top: 8px;
   }
   .select-multiple.theme .chevron .svg-inline--fa{
     height: 24px!important;
     width: auto!important;
   }
   .select-multiple.theme svg.svg-inline--fa.fa-angle-down.fa-w-10 {
    position: absolute;
    bottom: -7px;
    right: 3px;
   }
   .select-multiple.theme .show > .dropdown-menu {
    /* padding-left:10px; */
    width: 93%!important;
  }
 
  /* .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]) {
    border-left-color: #00718C!important;
    border-right-color: #00718C!important;
  } */
  .select-multiple.theme button.dropdown-item.active {
    color: black;
    text-decoration: none;
    background-color: #00718C;
  }
  .select-multiple.theme button.dropdown-item:focus {
    color: black;
  }
  .select-multiple.theme button.dropdown-item:hover {
    color: black;
    background-color: #dbf1f566;
  }
  .select-multiple.theme button.dropdown-item.active svg {
    color: white;
  }
  .select-multiple.theme  .hiddenLabel {
    transition: all 0.25s ease-out;
    position: absolute;
    bottom: 4px;
    left: 17px;
    visibility:hidden;
    
  }
  .select-multiple.theme{
    position:relative;
  }
  .select-multiple.theme .dropdown.show ~ label.floatingLabel {
    position: absolute;
    bottom: 20px;
    background: white;
    left: 8px;
    padding-right: 4px;
    padding-left: 4px;
    color:#00718C!important ;
    font-size: 11px;
    font-family:'Poppins';
    transition: all 0.25s ease-out;
  }
  .select-multiple.theme .dropdown  ~ label.floatingLabel {
    position: absolute;
    bottom: 20px;
    background: white;
    left: 8px;
    padding-right: 4px;
    padding-left: 4px;
    color:#A5A5A5!important ;
    font-size: 11px;
    font-family:'Poppins'
  }
  .select-multiple.theme .form-control:focus {
    color: black;
    font-family: 'Poppins';
    background-color: #fff;
    border: 2px solid #00718C!important;
  }

  .select-multiple .w-100.btn-group{
    border: unset;
    justify-content: center;
  }

</style>

<script scoped>
import { BButton, BButtonGroup, BCol, BDropdown, BDropdownDivider, BFormInput, BRow } from 'bootstrap-vue'
import OpenIdicator from '@/components/OpenIndicator'
import IconButton from "@/components/IconButton"
export default {
  OpenIdicator,
  data: () => ({
    filter: null,
    myActiveOptions: {},
    myFilteredOptions: [],
    myOptions: [],
    myVariant: null,
    icon: ['fas', 'chevron-down']
  }),
  created() {
    this.setup();
  },
  methods: {
    setup() {
      this.myOptions = this.options;
      this.myFilteredOptions = this.myOptions;
      this.myVariant = this.variant == 'outline-danger' ? this.variant : 'outline-secondary';
      for (let i = 0; i < this.value?.length; i++) {
        this.$set(this.myActiveOptions, this.value[i][this.optionValue], true);
      }
    },
    selectAll() {
      for (let i = 0; i < this.myFilteredOptions?.length; i++) {
        this.$set(this.myActiveOptions, this.myFilteredOptions[i][this.optionValue], true);
      }
      this.sendSignal();
    },
    unselectAll() {
      for (let i = 0; i < this.myFilteredOptions?.length; i++) {
        this.$set(this.myActiveOptions, this.myFilteredOptions[i][this.optionValue], false);
      }
      this.sendSignal();
    },
    toggleOption(option) {
      this.$set(this.myActiveOptions, option[this.optionValue], !this.myActiveOptions[option[this.optionValue]]);
      this.sendSignal();
    },
    textReducer(acc, current) {
      return `${acc}, ${current[this.optionLabel]}`
    },
    sendSignal() {
      if (!this.myActiveOptions) {
        this.$emit('input', []);
      }
      this.$emit('input', this.myOptions.filter(o => this.myActiveOptions[o[this.optionValue]]));
    },
  },
  computed: {
    text() {

      return this.myOptions
             .filter((option) => {
               return this.myActiveOptions[option[this.optionValue]]
             })
             .reduce(this.textReducer, '')
             .substring(2)
    },
    optionsNumber(){
      if(this.options)
        return this.options.length
      else
        return 0
    }
  },
  watch: {
    filter(newValue) {
      if (!newValue) {
        this.myFilteredOptions = this.myOptions;
        return;
      }
      this.myFilteredOptions = this.myOptions.filter(o => {
        let pattern = newValue.replaceAll(/[áÁ]/g, "a");
        pattern = pattern.replaceAll(/[éÉ]/g, "e");
        pattern = pattern.replaceAll(/[íÍ]/g, "i");
        pattern = pattern.replaceAll(/[óÓ]/g, "o");
        pattern = pattern.replaceAll(/[úÚ]/g, "u");
        let search = o[this.optionLabel].replaceAll(/[áÁ]/g, "a");
        search = search.replaceAll(/[éÉ]/g, "e");
        search = search.replaceAll(/[íÍ]/g, "i");
        search = search.replaceAll(/[óÓ]/g, "o");
        search = search.replaceAll(/[úÚ]/g, "u");
        let regexp = new RegExp(pattern, 'i');
        return search.match(regexp);
      })
    },
    options(newValue){
      this.myOptions = newValue
      this.myFilteredOptions = newValue
    },
    value(val){
      if(!val?.length){
        this.myActiveOptions = []
      }else{
        this.myActiveOptions = []
        for (let i = 0; i < val?.length; i++) {
          this.$set(this.myActiveOptions, val[i][this.optionValue], true)
        }
      }
    },
    variant(val){
      if(val == 'outline-danger')
        this.icon = ['fal', 'exclamation-circle']
      else
        this.icon = ['fas', 'chevron-down']
      this.myVariant = val == 'outline-danger' ? val : 'outline-secondary'
    }
  },
  props: [
    'optionLabel',
    'optionValue',
    'options',
    'placeholder',
    'value',
    'variant',
    'size',
    'disabled',
    'theme',
    'label'
  ],
  components: {
    BButton,
    BButtonGroup,
    BCol,
    BDropdown,
    BDropdownDivider,
    BFormInput,
    BRow,
    IconButton
  },
}
</script>