<template>
  <div>
    <b-row no-gutters>
      <b-col cols="3">
        <column-toggler v-model="myVisibleColumns" :options="columns"
                        option-label="label" option-value="label"/>
      </b-col>
    </b-row>
    <vue-good-table v-bind="$props"  @on-page-change="on_page_change" @on-per-page-change="on_per_page_change"
                    @on-sort-change="on_sort_change" @on-row-click="on_row_click" @on-cell-click="on_cell_click"
                    styleClass="vgt-table condensed bordered"
                    :columns="myVisibleColumns">
      <slot v-for="(_, name) in $scopedSlots" :name="name" :slot="name"/>
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
      <div slot="loadingContent">
        Cargando...
      </div>
      <div slot="emptystate" style="text-align: center">
      No hay registros
      </div>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a 
            </span>
            <b-form-select
              v-model="pageLength"
              :options="$props.paginationOptions.perPageDropdown"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"/>
            <span class="text-nowrap">de {{props.total}} registros</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="$props.paginationOptions.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})">
              <template #prev-text>
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </template>
              <template #next-text>
                <font-awesome-icon :icon="['fas', 'chevron-right']"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import { BRow, BCol } from 'bootstrap-vue'

import ColumnToggler from '@/components/ColumnToggler'

export default {
  name: 'DataTable',
  data: () => ({
    pageLength: 20,
    myVisibleColumns: [],
  }),
  created() {
    this.pageLength = this.paginationOptions.perPage
  },
  methods: {
    on_page_change(params) {
      this.$emit('on-page-change', params)
    },
    on_per_page_change(params) {
      this.$emit('on-per-page-change', params)
    },
    on_sort_change(params) {
      this.$emit('on-sort-change', params)
    },
    on_row_click(params){
      this.$emit('on-row-click', params)
    },
    on_cell_click(params){
      this.$emit('on-cell-click', params)
    }
  },
  watch:{
    columns(new_val){
      if (this.myVisibleColumns && this.myVisibleColumns.length)
        this.myVisibleColumns = new_val.filter(e => this.myVisibleColumns.find(f => f.field == e.field))
    }
  },
  components: {
    BRow, BCol,
    ColumnToggler,
    VueGoodTable,
  },
  props: VueGoodTable.props,
}
</script>
<style>
table.vgt-table tbody td {
  font-size: 0.9rem !important;
}
table.vgt-table thead th {
  font-size: 0.9rem !important;
}
.column-toggler .dropdown .dropdown-toggle {
  width: 100%;
}
table.vgt-table {
  font-size: 13px !important;
  border: 1px solid #ebe9f1 !important;
}
</style>
