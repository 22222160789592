import Vue from 'vue'
import Vuex from 'vuex'

import session from './session'
import sidebar from './sidebar'
import s3_gc from './s3_gc'
import vuexy from './vuexy'
import vuexy_config from './vuexy/config'
import vuexy_menu from './vuexy/vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session: session,
    sidebar: sidebar,
    's3-gc': s3_gc,
    app: vuexy,
    appConfig: vuexy_config,
    verticalMenu: vuexy_menu,
  }
})
