import qs from 'qs'

const use_pagination = (options) => {
  if (!options) return ''
  let pagination = {
    page: 0,
    size: 20,
  }
  let sort = ''

  if (options.page) pagination.page = options.page - 1
  if (options.size) pagination.size = options.size
  if (options.sort) {
    sort = options.sort.map(function(option) {
        let type = option.type !== 'none' ? option.type : 'asc'
        return `sort=${option.field},${type}`
    })
    sort = sort.join('&')
  }
  let query = qs.stringify(pagination)
  if (sort) query += ('&' + sort)
  return query
}

export default use_pagination