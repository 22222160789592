
export default {
  namespaced: true,
  state: {
    access_token: null,
    refresh_token: null,
    token_type: null,
    expires_in: null,
    username: null,
    scopes: [],
  },
  mutations: {
    'set-access-token': (state, token) => { state.access_token = token },
    'set-refresh-token': (state, token) => { state.refresh_token = token },
    'set-expires-in': (state, expires) => { state.expires_in = expires },
    'set-token-type': (state, type) => { state.token_type = type },
    'set-username': (state, username) => { state.username = username },
    'set-scopes': (state, scopes) => { state.scopes = scopes },
  },
  actions: {
    'set-authentication': function (context, data) {
      context.commit('set-access-token', data.access_token)
      context.commit('set-refresh-token', data.refresh_token)
      context.commit('set-expires-in', data.expires_in)
      context.commit('set-token-type', data.token_type)
      context.commit('set-scopes', data.scope.split(' '))
      context.commit('set-username', data.username)

      window.localStorage.setItem('access_token', data.access_token)
      window.localStorage.setItem('refresh_token', data.refresh_token)
      window.localStorage.setItem('expires_in', data.expires_in)
      window.localStorage.setItem('token_type', data.token_type)
      window.localStorage.setItem('scopes', data.scope)
      window.localStorage.setItem('username', data.username)
    },
    clear(context) {
      context.commit('set-access-token', null)
      context.commit('set-refresh-token', null)
      context.commit('set-expires-in', null)
      context.commit('set-token-type', null)
      context.commit('set-scopes', [])
      context.commit('set-username', null)

      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('refresh_token')
      window.localStorage.removeItem('expires_in')
      window.localStorage.removeItem('token_type')
      window.localStorage.removeItem('token_type')
      window.localStorage.removeItem('scopes')
      window.localStorage.removeItem('username')
    },
    lookup(context) {
      if (!window.localStorage.getItem('access_token')) return
      context.commit('set-access-token', window.localStorage.getItem('access_token'))
      context.commit('set-refresh-token', window.localStorage.getItem('refresh_token'))
      context.commit('set-expires-in', window.localStorage.getItem('expires_in'))
      context.commit('set-token-type', window.localStorage.getItem('token_type'))
      context.commit('set-scopes', window.localStorage.getItem('scopes').split(' '))
      context.commit('set-username', window.localStorage.getItem('username'))
    },
  },
  getters: {
    'access-token': (state) => state.access_token,
    'refresh-token': (state) => state.refresh_token,
    username: (state) => state.username,
    scopes: (state) => state.scopes,
  },
}
