<template>
  <div class="alert-form">
    <form-wizard :title="null" :subtitle="null" layout="vertical" backButtonText="Anterior" nextButtonText="Siguiente"
      class="wizard-vertical mb-3" color="#5FA2DD" ref="wizard">
      <tab-content title="Configuración de Alerta" :before-change="validateChange">
        <alert-step-one :url="url" :tokenuser="tokenuser" :alert="alert" :v="$v" @getUsers="getUsers" />
      </tab-content>
      <tab-content title="Remitente y Destinatario" :before-change="validateChange2">
        <alert-step-two :url="url" :tokenuser="tokenuser" :alert="alert" :v="$v" :completeUsers="completeUsers" />
      </tab-content>
      <tab-content title="Mensaje">
        <alert-step-three :url="url" :tokenuser="tokenuser" :alert="alert" :v="$v" :completeUsers="completeUsers" />
      </tab-content>
      <template slot="custom-buttons-right" v-if="idAlert">
        <b-button @click="save" variant="primary">
          <font-awesome-icon :icon="['fal', 'download']" />
          <span class="ml-50">Guardar</span>
        </b-button>
      </template>
      <template slot="custom-buttons-left">
        <b-button @click="cancel" variant="outline-secondary">
          <font-awesome-icon :icon="['fal', 'ban']" />
          <span class="ml-50">Cancelar</span>
        </b-button>
      </template>
      <template slot="next">
        <b-button variant="primary">
          <span class="mr-50">Siguiente</span>
          <font-awesome-icon :icon="['fal', 'angle-right']" />
        </b-button>
      </template>
      <template slot="prev">
        <b-button variant="outline-secondary">
          <font-awesome-icon :icon="['fal', 'angle-left']" />
          <span class="ml-50">Anterior</span>
        </b-button>
      </template>
      <template slot="finish">
        <b-button @click="save" v-if="!idAlert" variant="primary">
          <font-awesome-icon :icon="['fal', 'download']" />
          <span class="ml-50">Guardar</span>
        </b-button>
        <div v-else></div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { required, requiredIf } from 'vuelidate/lib/validators'
import AlertStepOne from './AlertStepOne.vue'
import AlertStepTwo from './AlertStepTwo.vue'
import AlertStepThree from './AlertStepThree.vue'
import AlertsService from '@/services/AlertsService'
import moment from 'moment'

export default {
  name: 'AlertsForm',
  components: {
    'form-wizard': FormWizard,
    'tab-content': TabContent,
    AlertStepOne,
    AlertStepTwo,
    AlertStepThree
  },
  props: ["idAlert", "url", "tokenuser"],
  validations: {
    alert: {
      nombreAlerta: { required },
      alertaProyectos: { required },
      tipoEvento: { required },
      eventoDisparador: { required },
      momentoEnvio: { required },
      fechaPersonalizada: {
        required: requiredIf(function () { return this.alert.tipoEvento === 'FECHA_PERSONALIZADA' })
      },
      usuarioEnvDesde: { required },
      envDesde: { required },
      envCliente: { required },
      asunto: { required },
      contenido: { required },
      alertaUsuarios: { required },
      numeroTimer: {
        required: requiredIf(function () { return this.alert.momentoEnvio !== 'AL_MOMENTO' })
      },
      timer: {
        required: requiredIf(function () { return this.alert.momentoEnvio !== 'AL_MOMENTO' })
      }
    }
  },
  data: () => ({
    alert: {
      id: null,
      nombreAlerta: '',
      alertaProyectos: [],
      tipoEvento: '',
      eventoDisparador: '',
      momentoEnvio: '',
      fechaPersonalizada: null,
      activo: true,
      usuarioEnvDesde: 0,
      envDesde: '',
      envCliente: false,
      asunto: '',
      contenido: '',
      alertaUsuarios: [],
      alertaDocumentos: [],
      numeroTimer: null,
      timer: null,
      addSign: true,
    },
    alertCopy: null,
    completeUsers: [],
    namesAlerts: []
  }),
  created() {
    this.init_data()
  },
  methods: {
    init_data() {
      if (this.idAlert) {
        AlertsService.getAlert(this.url, this.tokenuser, this.idAlert)
          .then(res => {
            this.alert = res
            this.alert.alertaTypeDocument = res.alertaTypeDocument ? JSON.parse(res.alertaTypeDocument) : []
            this.alert.alertaSubDeed = res.alertaSubDeed ? JSON.parse(res.alertaSubDeed) : []
            this.alert.alertaDeedProcess = res.alertaDeedProcess ? JSON.parse(res.alertaDeedProcess) : []
            this.alert.alertaUsuarios = JSON.parse(res.alertaUsuarios)
            this.alert.alertaProyectos = JSON.parse(res.alertaProyectos)
            this.alert.alertaSubestados = res.alertaSubestados ? JSON.parse(res.alertaSubestados) : []
            this.alert.alertaPaymentType = res.alertaPaymentType ? res.alertaPaymentType.split(',') : []
            this.alert.alertaDocumentos = res.alertaDocumentos ? JSON.parse(res.alertaDocumentos) : []
            this.alert.usuarioEnvDesde = res.usuarioEnvDesde ? res.usuarioEnvDesde : 0
            this.alert.eventoDisparador = res.eventoDisparador
            this.alert.fechaPersonalizada = res.fechaPersonalizada ? moment(res.fechaPersonalizada).format('DD-MM-YYYY') : null
          })
          .catch(() => this.alerts("error", "!Error!", `Ha ocurrido un error, por favor inténtelo de nuevo más tarde`))
          .finally(() => this.copyAlert())
      } else {
        this.copyAlert()
      }
    },
    async validateName(id) {
      var letras = "abcdefghijklmnopqrstuvwxyz";
      var clave = "";

      while (clave.length < 9) {
        var letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));

        if (clave.indexOf(letraAleatoria) === -1) {
          clave += letraAleatoria;
        }
      }
      let resultado = ""
      if (id != null) {
        resultado = id
      }
      else {
        resultado = 0
      }
      const names = await AlertsService.getNameAlerts(this.url, this.tokenuser, resultado)
      var nombreRepetido = names.some(a => a.trim() == this.alert.nombreAlerta.trim())
      if (nombreRepetido) {
        return false
      } else {
        return true
      }
    },
    async save() {
      console.log('aprete save', this.alert)
      const name = await this.validateName(this.alert.id)
      if (!this.alert.asunto || (!this.alert.contenido && (!this.alert.alertaDocumentos?.length))) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar todos los campos`)
        return
      } else if (this.alert.alertaDocumentos?.length) {
        var incluyeImagen = false;
        incluyeImagen = this.alert.alertaDocumentos.some(a => /jpe?g|png/i.test(a.tipo));
        if (!incluyeImagen && !this.alert.contenido) {
          this.alerts("warning", "!Advertencia!", `Necesitas anexar contenido al correo`)
          return
        } else if (!name) {
          this.alerts("warning", "!Advertencia!", `Ya existe una alerta con ese nombre`)
          return
        }
        else if (!this.alert.contenido) {
          this.$swal({
            title: "¿Desea continuar igualmente?",
            text: `No ha colocado ningún mensaje en el cuerpo del mail.`,
            type: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: 'Si, Guardar',
            cancelButtonText: 'Cancelar',
          }).then(async (result) => {
            if (!result.value) {
              return
            } else {
              this.saveChanges()
            }
          })
            .catch(() => this.alerts("error", "!Error!", `No fue posible realizar la operación, intente luego`))
        } else {
          this.saveChanges()
        }
      } else {
        this.saveChanges()
      }
    },
    saveChanges() {
      let editAlert = this.alert.id ? 1 : 0
      let auxProyect = this.alert.alertaProyectos
      let auxDocument = this.alert.alertaDocumentos
      let auxEvent = this.alert.eventoDisparador
      let auxSub = this.alert.alertaSubestados
      let auxSubDeed = this.alert.alertaSubDeed
      let auxTypeDocument = this.alert.alertaTypeDocument
      let auxDeedProcess = this.alert.alertaDeedProcess
      let auxPymentType = this.alert.alertaPaymentType

      this.alert.alertaSubDeed = this.alert.alertaSubDeed?.map(a => a.value) || []
      this.alert.alertaDeedProcess = this.alert.alertaDeedProcess?.map(a => a.value) || []
      this.alert.alertaTypeDocument = this.alert.alertaTypeDocument?.map(a => a.value) || []
      this.alert.alertaDeedProcess = this.alert.alertaDeedProcess?.map(a => a.value) || []
      this.alert.alertaDocumentos = this.alert.alertaDocumentos?.map(a => a.value) || []
      this.alert.alertaProyectos = this.alert.alertaProyectos?.map(a => a.value) || []
      this.alert.alertaSubestados = this.alert.alertaSubestados?.map(a => a.value) || []
      this.alert.alertaPaymentType = this.alert.alertaPaymentType?.map(a => a.text) || []

      this.alert.eventoDisparador = [this.alert.eventoDisparador]
      this.alert.numeroTimer = !this.alert.numeroTimer ? 0 : this.alert.numeroTimer

      AlertsService.saveAlert(this.url, this.tokenuser, editAlert, this.alert)
        .then(result => {
          if (result.status == 200) {
            this.alerts("success", "!Éxito!", `Los cambios se guardaron exitosamente`)
            this.$emit("closeForm")
          }
        })
        .catch(error => {
          this.alert.alertaProyectos = auxProyect
          this.alert.alertaSubestados = auxSub
          this.alert.alertaSubDeed = auxSubDeed
          this.alert.alertaTypeDocument = auxTypeDocument
          this.alert.alertaDeedProcess = auxDeedProcess
          this.alert.alertaDocumentos = auxDocument
          this.alert.eventoDisparador = auxEvent
          this.alert.alertaPaymentType = auxPymentType
          if (error.response.status == 500) {
            this.alerts("error", "!Error!", `No fue posible realizar la operación, intente luego`)
          } else {
            this.alerts("error", "!Error!", `${error.response.data.message}`)
          }
        })
    },
    async validateChange() {
      const nam = await this.validateName(this.alert.id)
      if (!this.alert.nombreAlerta || this.alert.alertaProyectos.length == 0 || !this.alert.momentoEnvio || !this.alert.tipoEvento || (this.alert.tipoEvento !== 'FECHA_PERSONALIZADA' && !this.alert.eventoDisparador)) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar todos los campos`)
        return false
      } else if (this.alert.tipoEvento === 'FECHA_PERSONALIZADA' && !this.alert.fechaPersonalizada) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar Fecha`)
        return false
      } else if (this.alert.momentoEnvio !== 'AL_MOMENTO' && (!this.alert.timer || !this.alert.numeroTimer)) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar todos los campos`)
        return false
      } else if (!nam) {
        this.alerts("warning", "!Advertencia!", `Ya existe una alerta con ese nombre`)
        return false
      } else {
        return true
      }
    },
    validateChange2() {
      if (!this.alert.envDesde || this.alert.alertaUsuarios.length <= 0) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar todos los campos`)
      }
      if (!this.alert.envDesde || this.alert.alertaUsuarios.length == 0) {
        this.alerts("warning", "!Advertencia!", `Necesitas completar todos los campos`)
        return false
      } else if (this.alert.envDesde == 'CORREO_ESPECIFICO' && !this.alert.mailEspecifico) {
        this.alerts("warning", "!Advertencia!", `Necesitas ingresar el Correo`)
        return false
      } else {
        return true
      }
    },
    alerts(type, message, subtext) {
      this.$swal({
        icon: type,
        title: message,
        text: subtext,
        showConfirmButton: false,
        timer: 1800,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    copyAlert() {
      this.alertCopy = { ...this.alert }
    },
    getUsers(project) {
      AlertsService.getListUsers(this.url, this.tokenuser, project)
        .then(response => {
          this.completeUsers = response.map(e => ({ text: e.nombre + " " + e.apellido, value: e.perfil.nombre, id: e.id }))
        })
        .catch(error => console.log(error))
    },
    cancel() {
      this.$swal({
        title: "¿Estás seguro de salir?",
        text: `Si tienes cambios los perderas`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: 'Si, Salir',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => result.value ? this.$emit("closeForm") : null)
        .catch(() => null)
    }
  },
}
</script>
<style></style>
