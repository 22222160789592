<template>
  <b-form-datepicker class="datepicker" v-model="myDate" button-only right @context="onContext" button-variant="outline-primary" 
                    :size="size" label-no-date-selected="No hay fecha seleccionada" 
                    label-help="Usa las flechas del teclado para navegar" :disabled="disabled ? true : false"/>
</template>
<script>
export default {
  props:['value', 'size', 'disabled'],
  data(){
    return{
      myDate: null,
    }
  },
  created(){
    this.myDate = this.value
  },
  methods:{
    onContext(ctx){
      this.$emit('context', ctx)
    }
  },
  watch:{
    value(val){
      if(val != this.myDate)
        this.myDate = val
    },
    myDate(val){
      if(val != this.value)
        this.$emit('input', val)
    }
  }
}
</script>
<style>
.input-group > .input-group-prepend > .btn-group > .btn, .input-group > .input-group-append:not(:last-child) > .btn-group > .btn, .input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn{
  padding: 0rem 1rem!important;
  border-top-right-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
}
.input-group > .input-group-append > .btn-group > .btn, .input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn, .input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  padding: 0rem 1rem!important;
  border-top-right-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
}
</style>