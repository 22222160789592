import axios from 'axios'
import use_pagination from '@/utils/use_pagination'

export default{
    getAlerts(url, tokenuser, options){
        const query = use_pagination(options)
        return new Promise(((resolve, reject) => {
            axios.get(`${url}mobysuite/listAlerts?uToken=${tokenuser}&${query}`,{})
            .then(response => resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getAlert(url, tokenuser, id){
        return new Promise(((resolve, reject) => {
            axios.get(url+"mobysuite/getAlert?uToken="+tokenuser+'&idAlert='+id)
            .then(response => resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    deleteItem(url, tokenuser, item){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/deleteAlert?uToken="+tokenuser+"&id="+item,{})
            .then(response=> resolve(response))
            .catch(err => reject(err))
        }))
    },
    saveAlert(url, tokenuser, editAlert, alert){
        return new Promise(((resolve, reject)=>{
            axios.post(url+"mobysuite/addAlert?uToken="+tokenuser+"&edit="+editAlert,{...alert})
            .then(response=> resolve(response))
            .catch(err => reject(err))
        }))
    },
    editState(url, tokenuser, id, active){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/changeAlertActive?uToken=${tokenuser}&id=${id}&active=${active}`)
            .then(response=> resolve(response))
            .catch(err => reject(err))
        }))
    },
    getDocuments(url, tokenuser, typeEvent){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/getDocuments?uToken=${tokenuser}&type=${typeEvent}`,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getTokens(url, tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/getTokens?uToken=${tokenuser}`,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListUsers(url, tokenuser, idProject){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/listUsers?uToken=${tokenuser}&project=${idProject}`,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getProjectsList(url,tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/listProjects?uToken="+tokenuser,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListPerfils(url,tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/listPerfils?uToken="+tokenuser,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getNameAlerts(url,tokenuser,id){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/getNamesAlerts?uToken="+tokenuser+"&id="+id,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListSubstates(url,tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/getSubestados?uToken="+tokenuser,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListSubDeed(url,tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/getSubestadosEscrituracion?uToken="+tokenuser,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getDeedProcess(url,tokenuser){
        return new Promise(((resolve, reject)=>{
            axios.get(url+"mobysuite/getProcesoEscrituracion?uToken="+tokenuser,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListPaymentType(url, tokenuser, idProject){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/listFormaPago?uToken=${tokenuser}&project=${idProject}`,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
    getListDocumentType(url, tokenuser, idProject){
        return new Promise(((resolve, reject)=>{
            axios.get(`${url}mobysuite/listTiposDocumento?uToken=${tokenuser}&project=${idProject}`,{})
            .then(response=> resolve(response.data))
            .catch(err => reject(err))
        }))
    },
}