<template>
  <div class="column-toggler">
    <b-dropdown variant="primary" no-flip @click.prevent="dropdown_click"
                v-b-tooltip.hover.right="">Configuración de columnas
      <template #button-content>
        <font-awesome-icon :icon="['far', 'cog']"/>
      </template>
      <b-dropdown-form>
        <b-row class="mb-1">
          <b-col no-gutters>
            <b-form-input v-model="filter"/>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col no-gutters>
            <b-button-group>
              <b-button class="word-no-break" variant="outline-primary"
                        @click="selectAll">
                Marcar Todos
              </b-button>
              <b-button class="word-no-break" variant="outline-primary"
                        @click="unselectAll">
                        Desmarcar Todos
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-dropdown-form>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-form>
        <b-row>
          <b-col cols="12" class="overflow-auto">
            <draggable v-model="myFilteredOptions" group="people" @end="onDrop">
              <div v-for="(option, idx) in myFilteredOptions" :key="idx">
                <button role="menuitem" type="button" class="dropdown-item" :class="{ active: myActiveOptions[option[optionValue]] }"
                        @click.prevent="toggleOption(option)">
                  {{ option[optionLabel] }}
                </button>
              </div>
            </draggable>
          </b-col>
        </b-row>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<style lang="css">
  .column-toggler .btn.dropdown-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dropdown .btn-group .btn {
    width: 12rem;
  }
  .dropdown-menu .dropdown-item {
    width: 100%;
  }
  .word-no-break {
    word-break: keep-all;
    white-space: nowrap;
  }
</style>

<script>
import Draggable from 'vuedraggable';
import { BButton, BButtonGroup, BCol, BDropdown, BDropdownDivider, BFormInput, BRow, VBTooltip } from 'bootstrap-vue'
import Dragger from '@/utils/Dragger';


export default {
  name: 'ColumnToggler',
  data: () => ({
    filter: null,
    myOptions: [],
    myFilteredOptions: [],
    myActiveOptions: {},
  }),
  directives: {
    'b-tooltip': VBTooltip
  },
  created() {
    this.setOptions(this.options)
  },
  updated() {
  },
  methods: {
    selectAll() {
      for (let i = 0; i < this.myFilteredOptions.length; i++) {
        this.$set(this.myActiveOptions, this.myFilteredOptions[i][this.optionValue], true);
      }
      this.sendSignal();
    },
    unselectAll() {
      for (let i = 0; i < this.myFilteredOptions.length; i++) {
        this.$set(this.myActiveOptions, this.myFilteredOptions[i][this.optionValue], false);
      }
      this.sendSignal();
    },
    toggleOption(option) {
      this.$set(this.myActiveOptions, option[this.optionValue], !this.myActiveOptions[option[this.optionValue]]);
      this.sendSignal();
    },
    textReducer(acc, current) {
      return `${acc}, ${current[this.optionValue]}`
    },
    sendSignal() {
      if (!this.myActiveOptions) {
        this.$emit('input', []);
        return;
      }
      this.$emit('input', this.myOptions.filter(o => this.myActiveOptions[o[this.optionValue]]));
    },
    onDrop(evt) {
      const result = Dragger.applyDrag(this.myFilteredOptions, this.myOptions, evt);
      this.myFilteredOptions = result.filtered;
      this.myOptions = result.original;
      this.sendSignal();
    },
    setOptions(newValue) {
      this.myOptions = newValue;
      this.myFilteredOptions = this.myOptions;
      if (!newValue || !newValue.length) {
        this.myActiveOptions = {};
        return;
      }
      for (let i = 0; i < this.myOptions.length; i++) {
        let option = this.myOptions[i];
        this.$set(this.myActiveOptions, option[this.optionLabel], true);
      }
      this.sendSignal();
    }
  },
  computed: {
  },
  watch: {
    filter(newValue) {
      if (!newValue) {
        this.myFilteredOptions = this.myOptions;
        return;
      }
      this.myFilteredOptions = this.myOptions.filter(o => {
        let pattern = newValue.replaceAll(/[áÁ]/g, "a");
        pattern = pattern.replaceAll(/[éÉ]/g, "e");
        pattern = pattern.replaceAll(/[íÍ]/g, "i");
        pattern = pattern.replaceAll(/[óÓ]/g, "o");
        pattern = pattern.replaceAll(/[úÚ]/g, "u");
        let search = o[this.optionLabel].replaceAll(/[áÁ]/g, "a");
        search = search.replaceAll(/[éÉ]/g, "e");
        search = search.replaceAll(/[íÍ]/g, "i");
        search = search.replaceAll(/[óÓ]/g, "o");
        search = search.replaceAll(/[úÚ]/g, "u");
        let regexp = new RegExp(pattern, 'i');
        return search.match(regexp);
      })
    },
    options(newValue) {
      this.setOptions(newValue)
    },
  },
  props: [
    'optionLabel',
    'optionValue',
    'options',
    'value',
  ],
  components: {
    BButton,
    BButtonGroup,
    BCol,
    BDropdown,
    BDropdownDivider,
    BFormInput,
    BRow,
    Draggable,
  },
}
</script>
