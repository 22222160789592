<template>
    <div class="">
        <h4>Definir Alerta</h4>
        <b-row>
            <b-col lg="6">
                <b-form-group label-for="Nombre Alerta" label="Nombre Alerta">
                    <b-form-input id="name" v-model="alert.nombreAlerta" />
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group label-for="project" label="Proyecto">
                    <select-multiple id="project" :options="projectsList"
                        v-b-tooltip="{ html: true, placement: 'bottom', variant: 'light', customClass: 'tooltipProject', trigger: 'hover' }"
                        v-b-tooltip.html="projectSelect" option-label="text" option-value="value"
                        v-model="alert.alertaProyectos" variant="outline-primary" placeholder="Seleccione Proyecto(s)">
                    </select-multiple>
                </b-form-group>
            </b-col>
        </b-row>
        <h4>Configuración de Disparo</h4>
        <b-row>
            <b-col lg="6">
                <b-form-group label="Filtro de disparo" label-for="event_type">
                    <single-select :viewTooltip="true" v-model="alert.tipoEvento" :options="typeOptions"
                        variant='outline-primary' id="event_type" placeholder="Seleccione Tipo de Evento" />
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group label-for="event" label="Evento disparador" v-if="alert.tipoEvento !== 'FECHA_PERSONALIZADA'">
                    <single-select :viewTooltip="true" v-model="alert.eventoDisparador" id="event" :options="event"
                        :disabled="alert.tipoEvento === 'FECHA_PERSONALIZADA'" variant="outline-primary"
                        placeholder="Seleccione Evento" />
                </b-form-group>
                <b-form-group label-for="substate" label="Subestado reserva" v-if="alert.eventoDisparador === '7'">
                    <select-multiple id="substate" :options="substatusList" option-label="text" option-value="value"
                        v-model="alert.alertaSubestados" variant="outline-primary" placeholder="Seleccione Subestado(s)">
                    </select-multiple>
                </b-form-group>
                <b-form-group label-for="substatedeed" label="Subestado escrituración"
                    v-if="alert.eventoDisparador === '12'">
                    <select-multiple id="substatedeed" :options="substatusdeedList" option-label="text" option-value="value"
                        v-model="alert.alertaSubDeed" variant="outline-primary" placeholder="Seleccione Subestado(s)">
                    </select-multiple>
                </b-form-group>
                <b-form-group label-for="deedProcess" label="Hito calendario escrituración"
                    v-if="alert.eventoDisparador === '12'">
                    <select-multiple id="deedProcess" :options="deedProcessList" option-label="text" option-value="value"
                        v-model="alert.alertaDeedProcess" variant="outline-primary"
                        placeholder="Seleccione hito calendario escrituración">
                    </select-multiple>
                </b-form-group>
                <b-form-group label-for="typeDocument" label="Tipo de documento"
                    v-if="alert.eventoDisparador === '25'">
                    <select-multiple id="typeDocument" :options="typeDocumentList" option-label="text" option-value="value"
                        v-model="alert.alertaTypeDocument" variant="outline-primary"
                        placeholder="Seleccione tipo de documento">
                    </select-multiple>
                </b-form-group>
                <b-form-group label-for="paymentType" label="Formas de pago" v-if="alert.tipoEvento === 'PAGOS'">
                    <select-multiple id="paymentType" :options="paymentTypeList" option-label="text" option-value="value"
                        v-model="alert.alertaPaymentType" variant="outline-primary" placeholder="Seleccione forma de pago">
                    </select-multiple>
                </b-form-group>
                <b-form-group label-for="date" label="Ingrese Fecha" v-if="alert.tipoEvento === 'FECHA_PERSONALIZADA'">
                    <b-input-group class="mb-1">
                        <b-form-input id="date" v-model="alert.fechaPersonalizada" type="text" placeholder="DD-MM-YYYY"
                            autocomplete="off" show-decade-nav />
                        <b-input-group-append>
                            <datepicker v-model="alert.fechaPersonalizada" class="mr-1" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group label="Momento de envío" label-for="momentoEnvio">
                    <div class="demo-inline-spacing">
                        <b-form-radio v-model="alert.momentoEnvio" plain name="Antes" :disabled="viewMoment" value="ANTES">
                            Antes
                        </b-form-radio>
                        <b-form-radio v-model="alert.momentoEnvio" plain name="Al momento" value="AL_MOMENTO">
                            Al momento
                        </b-form-radio>
                        <b-form-radio v-model="alert.momentoEnvio" plain name="Después" value="DESPUES">
                            Despues
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group v-if="alert.momentoEnvio && alert.momentoEnvio !== 'AL_MOMENTO'" label="Enviar"
                    label-for="enviarNumber">
                    <b-row>
                        <b-col lg="6">
                            <single-select :viewTooltip="true" class="bd-highlight" v-model="alert.timer"
                                :options="timerOptions" variant='outline-primary' id="date"
                                placeholder="Seleccione Tiempo" />
                        </b-col>
                        <b-col lg="5">
                            <b-form-input class="bd-highlight" id="send" v-model="alert.numeroTimer" type="number" />
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import SelectMultiple from "@/components/SelectMultiple"
import AlertsService from '@/services/AlertsService'
import SingleSelect from "@/components/SingleSelect"
import Datepicker from "@/components/Datepicker"
export default {
    name: 'AlertStepOne',
    components: { SelectMultiple, SingleSelect, Datepicker },
    data: () => ({
        projectSelect: { title: '' },
        substateSelect: { title: '' },
        paymentTypeSelect: { title: '' },
        actual: false,
        profiles: [],
        projectsList: [],
        substatusList: [],
        substatusdeedList: [],
        deedProcessList: [],
        typeDocumentList: [],
        paymentTypeList: [],
        typeOptions: [
            { value: 'CLIENTES', text: "Clientes", type: 0, newProperty: 1 },
            { value: 'COTIZACIONES', text: "Cotizaciones", type: 2 },
            { value: 'FECHA_PERSONALIZADA', text: "Fecha Personalizada", type: 0 },
            { value: 'PAGOS', text: "Pagos", type: 3 },
            { value: 'PROCESO_DE_CONTRATO', text: "Proceso de contrato", type: 3 },
            { value: 'PROYECTOS', text: "Proyectos", type: 0 }],
        event: [],
        timerOptions: [{ text: "Minutos", value: "MINUTOS" },
        { text: "Horas", value: "HORAS" },
        { text: "Días", value: "DIAS" },
        { text: "Semanas", value: "SEMANAS" }],
        viewMoment: false,

    }),
    props: ["alert", "url", "tokenuser", "v"],
    created() {
        this.init_data();
    },
    methods: {
        init_data() {
            AlertsService.getProjectsList(this.url, this.tokenuser)
                .then(response => {
                    this.projectsList = response.map(e => ({ text: e.nombreProyecto, value: e.id }))
                    if (this.alert.alertaProyectos.length) {
                        let proyectAux = this.alert.alertaProyectos
                        this.alert.alertaProyectos = []
                        for (var i = 0; i < proyectAux.length; i++) {
                            this.alert.alertaProyectos.push(this.projectsList.find(element => element.value == proyectAux[i]))
                        }
                    }
                })
                .catch(error => console.log(error))
            AlertsService.getListSubstates(this.url, this.tokenuser)
                .then(response => {
                    this.substatusList = response.map(e => ({ text: e.valor, value: e.id }))
                    if (this.alert.alertaSubestados?.length) {
                        let proyectAux = this.alert.alertaSubestados
                        this.alert.alertaSubestados = []
                        for (var i = 0; i < proyectAux.length; i++) {
                            this.alert.alertaSubestados.push(this.substatusList.find(element => element.value == proyectAux[i]))
                        }
                    }
                })
                .catch(error => console.log(error))
            AlertsService.getListSubDeed(this.url, this.tokenuser)
                .then(response => {
                    this.substatusdeedList = response.map(e => ({ text: e.valor, value: e.id }))
                    if (this.alert.alertaSubDeed?.length) {
                        let proyectAux = this.alert.alertaSubDeed
                        this.alert.alertaSubDeed = []
                        for (var i = 0; i < proyectAux.length; i++) {
                            this.alert.alertaSubDeed.push(this.substatusdeedList.find(element => element.value == proyectAux[i]))
                        }
                    }
                })
                .catch(error => console.log(error))
            AlertsService.getDeedProcess(this.url, this.tokenuser)
                .then(response => {
                    this.deedProcessList = response.map(e => ({ text: e.valor, value: e.id }))

                    if (this.alert.alertaDeedProcess?.length) {
                        let proyectAux = this.alert.alertaDeedProcess
                        this.alert.alertaDeedProcess = []
                        for (var i = 0; i < proyectAux.length; i++) {
                            this.alert.alertaDeedProcess.push(this.deedProcessList.find(element => element.value == proyectAux[i]))
                        }
                    }
                })
                .catch(error => console.log(error))
                
            AlertsService.getListDocumentType(this.url, this.tokenuser)
                .then(response => {
                    this.typeDocumentList = response.map(e => ({ text: e.valor, value: e.id }))

                    if (this.alert.alertaTypeDocument?.length) {
                        let proyectAux = this.alert.alertaTypeDocument
                        this.alert.alertaTypeDocument = []
                        for (var i = 0; i < proyectAux.length; i++) {
                            this.alert.alertaTypeDocument.push(this.typeDocumentList.find(element => element.value == proyectAux[i]))
                        }
                    }
                })
                .catch(error => console.log(error))

        },
        validate(val) {
            if (val.$params.required)
                return !val.$error && val.$model == null ? null : !val.$invalid
            else
                return val.$invalid ? false : null
        },
        alerts(type, message, subtext) {
            this.$swal({
                icon: type,
                title: message,
                text: subtext,
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
    },
    watch: {
        'alert.tipoEvento'(newValue) {

            switch (newValue) {
                case 'CLIENTES':
                    this.event = [{ text: "Ingreso cliente GC", value: '1', time: true },
                    { text: "Ingreso cliente desde la web", value: '2', time: true },
                    { text: "Fecha nacimiento cliente", value: '20', time: false },
                    { text: "Si es cliente PEP", value: '23', time: false },
                    ]
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                    break;
                case 'COTIZACIONES':
                    this.event = [{ text: "Cotización cliente", value: '3', time: true },
                    { text: "Cotización cliente desde la web", value: '4', time: true },
                    { text: "Cotización desde el centralizador", value: '5', time: true },
                    { text: "Vencimiento cotización", value: '6', time: false }]
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                    break;
                case 'PROCESO_DE_CONTRATO':
                    this.event = [{ text: "Reserva", value: '7', time: true },
                    { text: "Reserva web", value: '8', time: true },
                    { text: "Autorizar reserva", value: '9', time: true },
                    { text: "Rechazar reserva", value: '22', time: true },
                    { text: "Fecha de vencimiento de reserva", value: '10', time: false },
                    { text: "Promesa", value: '11', time: true },
                    { text: "Escrituración", value: '12', time: true },
                    { text: "Reversa contrato", value: '13', time: true },
                    { text: "Entrega", value: '24', time: true },
                    { text: "Documentos carpeta virtual", value: '25', time: true},]
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                    break;
                case 'PAGOS':
                    this.event = [{ text: "Documentación de pago", value: '14', time: true },
                    { text: "Fecha pactada en plan de pago del negocio del cliente", value: '15', time: false },
                    { text: "Fecha de documentado del pago", value: '16', time: true },
                    { text: "Reversa pago", value: '17', time: true }]

                    /**formas de pago 
                    AlertsService.getListPaymentType(this.url,this.tokenuser, this.alert.alertaProyectos?.map(a => a.value))
                    .then(response => {

                        this.paymentTypeList = response.map((e,index) => ({text: e, value: index}))

                        if(this.alert.alertaPaymentType?.length && !this.actual){
                            const filteredPaymentList = this.paymentTypeList.filter(({text}) => this.alert.alertaPaymentType.includes(text))
                            this.alert.alertaPaymentType=filteredPaymentList
                            this.actual = true
                        }
                    })
                    .catch(error => console.log(error))*/

                    if (this.alert.alertaProyectos?.length) {
                        AlertsService.getListPaymentType(this.url, this.tokenuser, this.alert.alertaProyectos?.map(a => a.value))
                            .then(response => {
                                this.paymentTypeList = response.map((e, index) => ({ text: e, value: index }))

                                if (this.alert.alertaPaymentType?.length) {
                                    let proyectAux = this.alert.alertaPaymentType
                                    this.alert.alertaPaymentType = []

                                    for (var i = 0; i < proyectAux.length; i++) {
                                        this.alert.alertaPaymentType.push(this.paymentTypeList.find(element => element.text == (proyectAux[i].value != null ? proyectAux[i].text : proyectAux[i])))
                                    }
                                }
                            })
                            .catch(error => console.log(error))
                    }

                    break;
                case 'PROYECTOS':
                    this.event = [{ text: "Fecha de entrega", value: '18', time: false },
                    { text: "Fecha de termino", value: '19', time: false }]
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                    break;
                case 'FECHA_PERSONALIZADA':
                    this.alert.envCliente = false
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                    break;
            }
            this.alert.eventoDisparador = this.event.find(element => element.value == this.alert.eventoDisparador) ? this.alert.eventoDisparador : null
        },
        'alert.eventoDisparador'(newValue) {
            if (newValue == '1') {
                this.alerts('warning', 'Advertencia', 'Siempre que se cree un cliente se enviara un mail, sin importar el proyecto seleccionado')
            }
            if (newValue) {
                let view = this.event.find(element => element.value == newValue)
                this.viewMoment = view.time
                this.alert.momentoEnvio = (view.time && this.alert.momentoEnvio == 'ANTES') ? null : this.alert.momentoEnvio
            }
        },
        'alert.alertaProyectos'(newValue) {
            if (newValue.length) {
                let project = []
                let names = ''
                newValue.forEach(a => {
                    if (a.value) {
                        project.push(a.value)
                        names = names + `<li>${a.text}</li>`
                    }
                })
                if (project.length != 0) {
                    this.projectSelect.title = newValue.length > 2 ? `<ul>${names}</ul>` : ''
                    this.$emit("getUsers", project.toString())
                }
                if (this.alert.tipoEvento === 'PAGOS' && this.alert.alertaProyectos?.length) {
                    AlertsService.getListPaymentType(this.url, this.tokenuser, this.alert.alertaProyectos?.map(a => a.value))
                        .then(response => {

                            this.paymentTypeList = response.map((e, index) => ({ text: e, value: index }))

                            if (this.alert.alertaPaymentType?.length) {
                                let proyectAux = this.alert.alertaPaymentType
                                this.alert.alertaPaymentType = []

                                for (var i = 0; i < proyectAux.length; i++) {
                                    this.alert.alertaPaymentType.push(this.paymentTypeList.find(element => element.text == (proyectAux[i].value != null ? proyectAux[i].text : proyectAux[i])))
                                }
                            }
                        })
                        .catch(error => console.log(error))
                } else {
                    this.alert.alertaPaymentType = []
                    this.paymentTypeList = []
                }
            }
        },
        'alert.alertaSubestados'(newValue) {
            if (newValue.length) {
                let sub = []
                let names = ''
                newValue.forEach(a => {
                    sub.push(a.value)
                    names = names + `<li>${a.text}</li>`
                })
                this.substateSelect.title = newValue.length > 2 ? `<ul>${names}</ul>` : ''
            }
        },
        'alert.alertaPaymentType'(newValue) {
            if (newValue != undefined && newValue.length) {
                let sub = []
                let names = ''
                newValue.map(a => {
                    sub.push(a.value)
                    names = names + `<li>${a.text}</li>`
                })
                this.paymentTypeSelect.title = newValue.length > 2 ? `<ul>${names}</ul>` : ''
            }
        },
    },
}
</script>
<style>
.vdatetime-input {
    border: none;
    color: #495057;
    width: 100%;
}

.demo-inline-spacing>* {
    margin-top: 0.5rem !important;
}

.my-tooltip-class {
    color: red;
}

.tooltipProject {
    top: -25px !important;
}</style>
