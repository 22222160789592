<template>
	<div>
		
	</div>
</template>

<script>
	//import Calendar from '../../../components/Calendar.vue';
	export default {
		name: 'App',
		props: {
			is_button: {default: true},
			is_flotating: {default: true},
			customer_id: {default: null },
			project_id: {default: null },
			country: {  default: 'CL' },
			top: {  default: null },
			bottom: {  default: '0px' },
			left: {  default: '0px' },
			right: {  default: null }
		},
		components: {
			//Calendar
		}
	}
</script>