<template>
  <div>
    <v-select 
      v-model="selectValue" 
      :placeholder="placeholder ? placeholder : 'Seleccione'" 
      :options="selectOptions"
      label="text"
      :state="variant == 'outline-primary' ? null : variant == 'outline-danger' ? false : true" 
      :disabled="disabled"
      :components="variant == 'outline-danger' ? {OpenIndicator} : null" 
      :class="`select-size-${size}`" 
      :reduce="option => option.value"
      :viewTooltip="viewTooltip">
      <span slot="no-options">
        <b-spinner small class="newSingleSelectSpinner"></b-spinner>
      </span>
      <template v-slot:option="option">
        <span v-b-tooltip="{ disabled: viewTooltip, title: option.text, placement:'right', variant:'light', customClass:'newtooltip', trigger:'hover'}">
          {{option.text}}
        </span>
      </template>
    </v-select>
  </div>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.single-select .vs__open-indicator {
    fill: #9CA0A4!important;
}

.form-group.is-invalid .v-select.vs--single.vs--searchable.select-size-undefined .vs__actions .vs__open-indicator {
    width: 28px!important;
    margin-top:5px!important;
    margin-left: -2px!important;
    display: flex!important;
    transform: unset!important;
}
.vs__open-indicator span svg {
    margin-left: -8px !important;
    margin-right: 6px;
}
.vs__dropdown-toggle {
  background-color: white!important;
  border: 1px solid #ced4da!important;
}
.vs__dropdown-menu :hover{
  color: #7367f0 !important;
  background-color: #eeedfd!important;
}
.vs__search{
  color: #6e6b7b;
}
.newtooltip{
  top:-30px!important;
}
.vs__selected-options{
  overflow: hidden !important;
}

</style>
<style lang="less">
.vs__dropdown-menu{
            span.newSingleSelectSpinner.spinner-border.spinner-border-sm{
                    color:#00718C;
            }
}
</style>

<script>
import vSelect from 'vue-select'
import OpenIndicator from './OpenIndicator.vue'

export default {
  OpenIndicator,
  data: () => ({
    selectValue: null,
    selectOptions: [],
    OpenIndicator: {
      render: createElement => createElement(OpenIndicator),
    },
  }),
  created() {
    this.selectOptions = this.options
    if(this.value){
      if(this.optionValue){
        if(this.selectOptions && this.selectOptions.length)
          this.selectValue = this.selectOptions.find(e => e.value[this.optionValue] == this.value[this.optionValue]).value
      }else{
        this.selectValue = this.value
      }
    }
  },
  watch:{
    value(val){
      if(this.selectValue != val){
        if(this.optionValue){
          if(this.options && this.options.length && val){
            this.selectValue = this.options.find(e => e.value[this.optionValue] == val[this.optionValue]).value
          }else if(!val){
            this.selectValue = null
          }
        }else{
          this.selectValue = val
        }
      }
    },
    options(val){
      this.selectOptions = val
      if(this.optionValue && this.value && this.selectValue == null)
        this.selectValue = this.selectOptions.find(e => e.value[this.optionValue] == this.value[this.optionValue]).value
    },
    selectValue(val){
      if(this.optionValue){
        if(val){
          if(!this.value || (val[this.optionValue] != this.value[this.optionValue])){
            this.$emit('input', this.selectOptions.find(e => e.value[this.optionValue] == val[this.optionValue]).value)
          }
        }else{
          this.$emit('input', null)
        }
      }else{
        if(val != this.value){
          if(val){
            this.$emit('input', this.selectOptions.find(e => e.value == val).value)
            this.$emit('changeOption')
          }
          else
            this.$emit('input', null)
        }
      }
    }
  },
  props: [
    'options',
    'placeholder',
    'value',
    'variant',
    'size',
    'disabled',
    'optionValue',
    'viewTooltip'
  ],
  components: {
    vSelect,
  },
}
</script>


