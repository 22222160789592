
export default {
  namespaced: true,
  state: {
    options: [],
    current: null,
  },
  mutations: {
    'set-current': (state, key) => { state.current = key },
    'set-options': (state, options) => { state.options = options },
  },
  actions: {
    'set-current': (context, key) => { context.commit('set-current', key) },
    'set-options': (context, options) => {
      context.commit('set-options', options)
    },
  },
  getters: {
    current: (state) => state.current,
    options: (state) => state.options,
  },
}
