import {faHome as fadHome} from '@fortawesome/pro-duotone-svg-icons'
import {faSort as fadSort} from '@fortawesome/pro-duotone-svg-icons'
import {faSortDown as fadSortDown} from '@fortawesome/pro-duotone-svg-icons'
import {faSortUp as fadSortUp} from '@fortawesome/pro-duotone-svg-icons'

import {faBan as falBan} from '@fortawesome/pro-light-svg-icons'
import {faBriefcase as falBriefcase} from '@fortawesome/pro-light-svg-icons'
import {faAngleLeft as falAngleLeft} from '@fortawesome/pro-light-svg-icons'
import {faAngleRight as falAngleRight} from '@fortawesome/pro-light-svg-icons'
import {faCircle as falCircle} from '@fortawesome/pro-light-svg-icons'
import {faCity as falCity} from '@fortawesome/pro-light-svg-icons'
import {faChartLine as falChartLine} from '@fortawesome/pro-light-svg-icons'
import {faClipboardListCheck as falClipboardListCheck} from '@fortawesome/pro-light-svg-icons'
import {faCloudUpload as falCloudUpload} from '@fortawesome/pro-light-svg-icons'
import {faCreditCard as falCreditCard} from '@fortawesome/pro-light-svg-icons'
import {faDotCircle as falDotCircle} from '@fortawesome/pro-light-svg-icons'
import {faDownload as falDownload} from '@fortawesome/pro-light-svg-icons'
import {faExchangeAlt as falExchangeAlt} from '@fortawesome/pro-light-svg-icons'
import {faEye as falEye} from '@fortawesome/pro-light-svg-icons'
import {faFile as falFile} from '@fortawesome/pro-light-svg-icons'
import {faFileAlt as falFileAlt} from '@fortawesome/pro-light-svg-icons'
import {faFileArchive as falFileArchive} from '@fortawesome/pro-light-svg-icons'
import {faFileCheck as falFileCheck} from '@fortawesome/pro-light-svg-icons'
import {faFileDownload as falFileDownload} from '@fortawesome/pro-light-svg-icons'
import {faFileEdit as falFileEdit} from "@fortawesome/pro-light-svg-icons";
import {faFileExcel as falFileExcel} from '@fortawesome/pro-light-svg-icons'
import {faFileInvoice as falFileInvoice} from '@fortawesome/pro-light-svg-icons'
import {faFileInvoiceDollar as falFileInvoiceDollar} from '@fortawesome/pro-light-svg-icons'
import {faFilePdf as falFilePdf} from '@fortawesome/pro-light-svg-icons'
import {faGavel as falGavel} from '@fortawesome/pro-light-svg-icons'
import {faHistory as falHistory} from '@fortawesome/pro-light-svg-icons'
import {faIdCardAlt as falIdCardAlt} from '@fortawesome/pro-light-svg-icons'
import {faInfoCircle as falInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {faKey as falKey} from "@fortawesome/pro-light-svg-icons";
import {faLockAlt as falLockAlt} from '@fortawesome/pro-light-svg-icons'
import {faMobile as falMobile} from '@fortawesome/pro-light-svg-icons'
import {faMoon as falMoon} from '@fortawesome/pro-light-svg-icons'
import {faMoneyCheckEdit as falMoneyCheckEdit} from '@fortawesome/pro-light-svg-icons'
import {faPencilAlt as falPencilAlt} from '@fortawesome/pro-light-svg-icons'
import {faPhoneVolume as falPhoneVolume} from '@fortawesome/pro-light-svg-icons'
import {faProjectDiagram as falProjectDiagram} from '@fortawesome/pro-light-svg-icons'
import {faRedoAlt as falRedoAlt} from '@fortawesome/pro-light-svg-icons'
import {faSlidersH as falSlidersH} from '@fortawesome/pro-light-svg-icons'
import {faSun as falSun} from '@fortawesome/pro-light-svg-icons'
import {faTrash as falTrash} from '@fortawesome/pro-light-svg-icons'
import {faTrashUndoAlt as falTrashUndoAlt} from '@fortawesome/pro-light-svg-icons'
import {faUniversity as falUniversity} from '@fortawesome/pro-light-svg-icons'
import {faUser as falUser} from "@fortawesome/pro-light-svg-icons";
import {faUsers as falUsers} from '@fortawesome/pro-light-svg-icons'
import {faVideo as falVideo} from '@fortawesome/pro-light-svg-icons'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons'
import { faPaperPlane as falPaperPlane } from '@fortawesome/pro-light-svg-icons'

import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons'
import { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons'
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons'
import { faCog as farCog } from '@fortawesome/pro-regular-svg-icons'
import { faFilePdf as farFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons'
import { faUsdCircle as farUsdCircle } from '@fortawesome/pro-regular-svg-icons'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons'

import { faRedo as fasRedo } from '@fortawesome/pro-solid-svg-icons'
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons'
import { faBan as fasBan } from '@fortawesome/pro-solid-svg-icons'
import { faAlignCenter as fasAlignCenter } from '@fortawesome/pro-solid-svg-icons'
import { faAlignLeft as fasAlignLeft } from '@fortawesome/pro-solid-svg-icons'
import { faAlignRight as fasAlignRight } from '@fortawesome/pro-solid-svg-icons'
import { faBinoculars as faBinoculars } from '@fortawesome/pro-solid-svg-icons'
import { faBold as fasBold } from '@fortawesome/pro-solid-svg-icons'
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft as fasChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleRight as fasChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import {faChevronDown as falChevronDown} from  '@fortawesome/pro-light-svg-icons'
import {faTimes as falTimes} from  '@fortawesome/pro-light-svg-icons'
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { faEllipsisH as fasEllipsisH } from '@fortawesome/pro-solid-svg-icons'
import { faHighlighter as fasHighlighter } from '@fortawesome/pro-solid-svg-icons'
import { faItalic as fasItalic } from '@fortawesome/pro-solid-svg-icons'
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons'
import { faListOl as fasListOl } from '@fortawesome/pro-solid-svg-icons'
import { faListUl as fasListUl } from '@fortawesome/pro-solid-svg-icons'
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons'
import { faUnderline as fasUnderline } from '@fortawesome/pro-solid-svg-icons'
import { faUnlink as fasUnlink } from '@fortawesome/pro-solid-svg-icons'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import { faMinusCircle as fasMinusCircle } from '@fortawesome/pro-solid-svg-icons'
import { faAngleDown as fasAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { faPhoneAlt as fasPhoneAlt } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { faSignOutAlt as fasSignOutAlt } from '@fortawesome/pro-solid-svg-icons'
import { faUserPlus as fasUserPlus } from '@fortawesome/pro-solid-svg-icons'
import { faVideo as fasVideo } from '@fortawesome/pro-solid-svg-icons'
import { faFileAlt as fasFileAlt } from '@fortawesome/pro-solid-svg-icons'
import { faFilePdf as fasFilePdf } from '@fortawesome/pro-solid-svg-icons'
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons'
import {faExclamationCircle as faExclamationCircle} from '@fortawesome/pro-light-svg-icons';
import {faExclamationCircle as farExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle as faCheckCircle} from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash as fasEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import {faFileWord as faFileWord} from  '@fortawesome/pro-light-svg-icons';
import {faFileExcel as faFileExcel} from  '@fortawesome/pro-light-svg-icons';
import {faSlidersH as farSlidersH} from '@fortawesome/pro-regular-svg-icons';
import {faStreetView as faStreetView} from '@fortawesome/pro-light-svg-icons';
import {faFileInvoiceDollar as faFileInvoiceDollar} from '@fortawesome/pro-light-svg-icons';

import {faFacebookF as fabFacebookF} from '@fortawesome/free-brands-svg-icons'
import {faInstagram as fabInstagram} from '@fortawesome/free-brands-svg-icons'
import {faWhatsapp as fabWhatsapp} from '@fortawesome/free-brands-svg-icons'



export default [
  fabFacebookF,
  fabInstagram,
  fabWhatsapp,
  fadHome,
  fadSort,
  fadSortDown,
  fadSortUp,
  falBan,
  falBriefcase,
  falCircle,
  falCity,
  falChartLine,
  falClipboardListCheck,
  falCloudUpload,
  falCreditCard,
  falDotCircle,
  falDownload,
  falExchangeAlt,
  falEye,
  falFile,
  falFileAlt,
  falFileArchive,
  falFileCheck,
  falFileDownload,
  falFileEdit,
  falFileExcel,
  falFileInvoiceDollar,
  falFileInvoice,
  falFilePdf,
  falGavel,
  falHistory,
  falIdCardAlt,
  falInfoCircle,
  falKey,
  falLockAlt,
  falMobile,
  falMoon,
  falMoneyCheckEdit,
  falPencilAlt,
  falPhoneVolume,
  falProjectDiagram,
  falRedoAlt,
  falSlidersH,
  falSun,
  falTrash,
  falTrashUndoAlt,
  falUniversity,
  falUser,
  falUsers,
  falVideo,
  falEnvelope,
  falPaperPlane,
  falTimes,
  farBars,
  farCalendar,
  farClock,
  farCog,
  farFilePdf,
  farPlus,
  farUsdCircle,
  fasAngleDown,
  farTimes,
  farClock,
  fasFileAlt,
  fasFilePdf,
  fasMinusCircle,
  fasVideo,
  fasUserPlus,
  fasRedo,
  fasBan,
  fasCheck,
  fasDownload,
  fasAlignCenter,
  fasAlignLeft,
  fasAlignRight,
  faBinoculars,
  fasBold,
  fasCaretDown,
  fasChevronDoubleLeft,
  fasChevronDoubleRight,
  fasChevronUp,
  fasChevronDown,
  falChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasEllipsisH,
  fasHighlighter,
  fasItalic,
  fasLink,
  fasListOl,
  fasListUl,
  fasSearch,
  fasUnderline,
  fasUnlink,
  fasPlusCircle,
  fasPhoneAlt,
  fasEnvelope,
  fasMapMarkerAlt,
  fasSignOutAlt,
  fasEye,
  faExclamationCircle,
  faCheckCircle,
  faFileWord,
  faFileExcel,
  fasEyeSlash,
  farExclamationCircle,
  farSlidersH,
  faStreetView,
  faFileInvoiceDollar,
  falAngleLeft,
  falAngleRight,
]
