<template>
    <div>
        <h4>Remitente</h4>
        <b-row>
            <b-col lg="6">
                <b-form-group label="Enviar desde" label-for="sendClient">
					<single-select :viewTooltip="true" v-model="alert.envDesde" :options="send" id="sendClient"/>
				</b-form-group>
            </b-col>
            <b-col lg="6" v-if="alert.envDesde == 'CORREO_ESPECIFICO'">
				<b-form-group label="Correo" label-for="mail">
					<b-form-input id="mail" v-model="alert.mailEspecifico"/>
				</b-form-group>
			</b-col>
            <b-col lg="6" v-if="alert.envDesde == 'USUARIO_ESPECIFICO'">
				<b-form-group label="Usuario correo" label-for="userMail">
					<single-select :viewTooltip="true" id="userMail" :options="mails" option-label="text"  v-model="alert.usuarioEnvDesde"
                        variant="outline-primary" placeholder="Seleccione usuario"/>
				</b-form-group>
			</b-col>
        </b-row>
        <h4>Destinatario</h4>
        <b-row>
            <b-col lg="6">
                <b-form-group label="Tipo de destinatario:" label-for="addresseeType" >
                    <single-select :viewTooltip="true" v-model="user.tipo" :options="tipos" variant='outline-primary' id="addresseeType" aria-selected="1" aria-placeholder="Destinatarios"/>
                </b-form-group>
            </b-col>
             <b-col lg="6">
                <b-form-group label="Seleccione Perfil" label-for="profile" >
                    <single-select :viewTooltip="true" v-model="user.perfil" :options="profiles" variant='outline-primary' id="profile" aria-selected="1" aria-placeholder="Perfil"/>
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group label="Seleccione Usuario" label-for="users" >
                    <select-multiple id="users" :options="users" option-label="text" name="users" v-model="user.name"
                             option-value="text" variant="outline-primary" placeholder="Usuario(s)"/>
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group label="Enviar al Cliente" label-for="send">
					<b-form-checkbox id="send" v-model="alert.envCliente" :disabled="alert.tipoEvento === 'FECHA_PERSONALIZADA'"/>
				</b-form-group>
            </b-col>
            <b-col lg="6">
                <b-button @click="save" variant="primary" class="buttonAdd">
                    <font-awesome-icon :icon="['far', 'plus']" />
                    <span  class="ml-50">Añadir</span>
                </b-button>
            </b-col>
        </b-row>
        <data-table class="pt-1 mh-50" :columns="table.cols" :rows="table.rows" :pagination-options="table.pagination" :totalRows="table.total"
            :isLoading.sync="loading">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column && props.column.field == 'actions'">
                    <div toggle-class="text-decoration-none" no-caret>
                        <b-button size="sm" variant="danger" @click="deleteAddressee(props.index)" class="mr-1">
                            <font-awesome-icon :icon="['fal', 'trash']" class="mr-50" />
                            <span>Eliminar</span>
                        </b-button>
                    </div>
                    </span>
            </template>
        </data-table>
    </div>
</template>
<script>
import SelectMultiple from "@/components/SelectMultiple"
import DataTable from '@/components/DataTable.vue'
import SingleSelect from "@/components/SingleSelect"
import AlertsService from '@/services/AlertsService'

export default {
    name:"AlertStepThree",
    components: {
        SelectMultiple,
        DataTable,
        SingleSelect
    },
    data: () => ({
        loading: false,
        mails:[],
        send: [{text:"Correo del usuario generador del evento", value: "GENERADOR_EVENTO"},
            {text:"Usuario asignado al lead", value:"USUARIO_LED"},
            {text:"Correo del ejecutivo de reserva del contrato", value: "EJECUTIVO_RESERVA"},
            {text:"Correo del ejecutivo de promesa del contrato", value: "EJECUTIVO_PROMESA"},
            {text:"Correo del ejecutivo de escrituración del contrato", value:"EJECUTIVO_ESCRITURACION"},
            {text:"Correo de usuario del sistema", value: "USUARIO_ESPECIFICO"},
            {text:"Correo específico", value:"CORREO_ESPECIFICO"}],
        tipo: null,
        tipos:[{ text: "Destinatarios", value: "DEST" }, { text: "Copia", value: "CC" }, { text: "Copia oculta", value: "CCO" }],
        profiles: [],
        infoProfiles: [],
        profile: null,
        users: [],
        user: {},
        usersSelected: [],
    }),
    props:["url","tokenuser", "alert","v", "completeUsers"],
    created(){
        this.mails = this.completeUsers.map( e => ({text: `${e.nombre} ${e.apellido}`, value:e.id}))
        this.users = [...this.completeUsers]
        AlertsService.getListPerfils(this.url, this.tokenuser)
        .then(response => {
            this.profiles = response.map( e => ({text: e.nombre, value: e.nombre}));
            this.profiles.unshift({text: "Todos", value: "Todos"});
        })
        .catch(() => this.profiles = [{text: "Todos", value: "Todos"}])
    },
    watch:{
        "alert.envDesde"(newValue){
            this.alert.mailEspecifico = newValue !== 'CORREO_ESPECIFICO' ? null : this.alert.mailEspecifico
        },
        "user.perfil"(newValue){
            this.user.name = null
            this.users = newValue == "Todos" || !newValue ? this.completeUsers : this.completeUsers.filter(element => element.value === newValue)
        },
        "completeUsers"(newValue){
            this.mails = newValue.map( e => ({text: e.text, value:e.id}))
            this.users = [...newValue]
        }
    },
    methods: {
    alerts(type, message, subtext){
        this.$swal({
          icon: type,
          title: message,
          text: subtext,
          showConfirmButton: false,
          timer: 1700,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
            buttonsStyling: false,
        })
    },
    save() {
        if(this.user.tipo == null){
            this.alerts('warning','Error',"Debe seleccionar tipo de destinatario para poder agregar")
        }else if(this.user.name == null || !this.user.name.length){
            this.alerts('warning','Error',"Debe seleccionar al menos un usuario")
        }else{
            for(var i = 0; i < this.user.name.length ; i++){
                let incluyed = false
                let userAux = { name:this.user.name[i].text,
                                perfil:this.user.name[i].value,
                                tipo:this.user.tipo,
                                id:this.user.name[i].id}
                this.alert.alertaUsuarios.find(file=>{
                    if(file.id == userAux.id) incluyed = true
                })
                if(!incluyed) this.alert.alertaUsuarios.push(userAux)
            }
            this.user.name=[]
        }
    },
    deleteAddressee(index) {
        this.alert.alertaUsuarios.splice(index,1)
    },
},
computed: {
    table() {
      return {
        cols: [
          { label: "Nombre Usuario", field: 'name',},
          { label: "Perfil", field: 'perfil',},
          { label: "Tipo destinatario", field: 'tipo',formatFn: (a) => (this.tipos.find(e => e.value == a).text)},
          { field: 'actions', label: 'Acciones', sortable:false },
        ],
        rows: this.alert.alertaUsuarios,
        total: this.alert.alertaUsuarios.length,
        pagination: {
          enabled: true,
          perPage: 20,
          perPageDropdown: [20, 50, 100, 1000],
        },
      }
    }
  },
}
</script>
<style lang="scss" scoped>
    #date {
        border: gray 1px solid;
        border-radius: 5px;
    }
    .buttonAdd{
        margin-top:4vh;
    }
</style>
