<template>
  <div>
    <b-row>
			<b-col xl="12" bgcolor="#5FA2DD">
				<table width="2880" cellspacing="1" cellpadding="3" border="0" bgcolor="#5FA2DD">
					<tr>
						<td><font color="#FFFFFF" face="arial, verdana, helvetica">
						<b></b>
						</font></td>
					</tr>
				</table>
			</b-col>
		</b-row>
  <div class="m-3">
    <demo-item v-if="hidden" @openForm="openForm" :url="url" :tokenuser="tokenuser"></demo-item>
    <alerts-form v-else-if="!hidden"  @closeForm="closeForm" :url="url" :idAlert="idAlert" :tokenuser="tokenuser"></alerts-form>
  </div>
  </div>
</template>

<script>
import DemoItem from './views/alerts/Demo'
import AlertsForm from '@/views/alerts/includes/AlertsForm'

export default {
  name: 'App',
  data: () => ({
    hidden:true,
    idAlert: null,
  }),
  props:{
    url: {default:""},
    tokenuser: {default: ""}
  },
  computed: {
    contentLayoutType(){
      return this.$store.state.appConfig.layout.type
    },
    blankLayout(){
      if(this.$route.name === 'log_in'){
        return true;
      }
      return false;
    }
  },
  components: {
    DemoItem,
    AlertsForm
  },
  methods:{
    closeForm(){
      this.hidden = true
    },
    openForm(id){
      this.idAlert = id ? id : null
      this.hidden = false
    },
  }
}
</script>
<style lang="scss">

  mobysuite-alert-widget {
  @import '@/vendor/vuexy/scss/core.scss';
  @import "assets/scss/bootstrap";
  
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
  text-align: left;
  overflow-x: hidden;

  h4{
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    margin-top: 0 !important;
    font-family: inherit !important;
    color: #5e5873 !important;
  }
  mobysuite-alert-widget .page-item.active .page-link, .page-link  {
    border-radius: 50% !important;
  } 
  th.vgt-left-align.sortable, th.vgt-left-alignth, th.vgt-left-align{
    vertical-align: middle !important;
  }
  mobysuite-alert-widget .custom-switch .custom-control-label::after,
  mobysuite-alert-widget .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
    margin-top:2px  !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before, .custom-switch .custom-control-label::before{
    padding: 0  !important;
  }
  .page-item.disabled .page-link{
    border-color:transparent !important;
    margin: 0 .3571rem  !important;
  }
  mobysuite-alert-widget .btn-outline-secondary:hover,
  .btn-outline-secondary:hover{
    color:#6e6b7b !important;
    background-color: #fff !important;
  }
  .vs--searchable .vs__dropdown-toggle{
    height: 2.714rem;
  }
  .buttonAdd[data-v-2041016a]{
    margin-top:0;
    margin-bottom: 2vh;
  }
  .btn-outline-secondary.dropdown-toggle::after,
  .btn-outline-primary.dropdown-toggle::after{
    display: none;
  }
  mobysuite-alert-widget .page-link{
    color: #2c3e50 !important;
  }
  .form-control,
  .vs__selected{
    color:#6e6b7b !important;
  }
  }
</style>

