<template>
    <div>
        <h4>Mensaje</h4>
        <b-row>
            <b-col lg='6'>
                <b-form-group label="Asunto" label-for="subject" >
                    <b-form-input v-model="alert.asunto" variant='outline-primary' id="subject" placeholder="Ingrese Asunto"/>
                </b-form-group>
            </b-col>
            <b-col lg='9'>
                <b-form-group label="Mensaje" label-for="message" id="formQuill">
                    <quill-editor v-model="alert.contenido" id="message"  ref="content" :options="editorOption" @change=contentChange($event)>
                        <div id="toolbar" slot="toolbar">
                            <button class="ql-bold"> Bold </button>
                            <button class="ql-italic"> Italic </button>
                            <select class="ql-size">
                                <option value="small" />
                                <option selected />
                                <option value="large" />
                                <option value="huge" />
                            </select>
                            <select class="ql-font">
                                <option selected="selected" />
                                <option value="serif" />
                                <option value="monospace" />
                            </select>
                            <button class="ql-script" value="sub" />
                            <button class="ql-script" value="super" />
                            <button class="ql-code-block"> Code </button>
                        </div>
                    </quill-editor>
                </b-form-group>
                <p class="note">* No colocar links externos ya que pueden caer en spam</p>
            </b-col>
            <b-col lg='3'>
                <b-form-group label="Etiqueta" label-for="tokens" >
                    <single-select v-model="token" :viewTooltip="false" :options="tokens" variant='outline-primary' id="tokens" placeholder="Seleccione etiqueta"/>
                </b-form-group>
                <b-button @click="addToken" variant="primary" clas="mt-3" :disabled="!token">
                    <font-awesome-icon :icon="['far', 'plus']" />
                    <span class="ml-50">Añadir</span>
                </b-button>
            </b-col>
        </b-row>
        <h4>Firma</h4>
        <b-row>
            <b-col lg='6'>
                <b-form-group label="Usuario firmante" label-for="signingUser" >
                    <b-form-input v-model="alert.sign" variant='outline-primary' id="signingUser" placeholder="Mismo usuario que el remitente" :disabled="true"/>
                </b-form-group>
            </b-col>
            <b-col lg='6'>
                <b-form-group label="Firma visible" label-for="sign">
					<b-form-checkbox id="sign" v-model="alert.addSign"/>
				</b-form-group>
            </b-col>
        </b-row>
        <h4>Adjuntar documento</h4>
        <b-row>
            <b-col lg='6'>
                <b-form-group label="Documento" label-for="document" >
                    <select-multiple id="document" :options="documents" option-label=text name="document" v-model="document"
                            option-value="value" variant="outline-primary" placeholder="Seleccione documento(s)"/>
                </b-form-group>
            </b-col>
            <b-col lg='6'>
                <b-button @click="addDocument" class="buttonAdd" variant="primary" clas="mt-3">
                    <font-awesome-icon :icon="['far', 'plus']" />
                    <span class="ml-50">Añadir Documento</span>
                </b-button>
            </b-col>
        </b-row>
        <data-table  :key="componentKey" class="pt-1 mh-50" :columns="table.cols" :rows="table.rows" :pagination-options="table.pagination" :totalRows="table.total"
                :isLoading.sync="loading">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column && props.column.field == 'actions'">
                    <div toggle-class="text-decoration-none" no-caret>
                        <b-button variant="outline-primary" size="sm" class="mr-1">
                            <a :href=props.row.url target="_blank">Ver</a> 
                        </b-button>
                        <b-button variant="danger" size="sm" @click="deleteDocument(props.row.originalIndex)" class="mr-1">
                            <font-awesome-icon :icon="['fal', 'trash']" class="mr-50" />
                        </b-button>
                    </div>
                </span>
            </template>
        </data-table>
    </div>
</template>
<style lang="scss" scoped>
#date {
  border: gray 1px solid;
  border-radius: 5px;
}
</style>
<script>
import SelectMultiple from "@/components/SelectMultiple"
import SingleSelect from "@/components/SingleSelect"
import DataTable from '@/components/DataTable.vue'
import AlertsService from '@/services/AlertsService'
import { quillEditor } from 'vue-quill-editor'

export default {
    name: "AlertStepThree",
    components : {
        quillEditor,
        SingleSelect,
        SelectMultiple,
        DataTable
    },
    data: () => ({
        componentKey: 0,
        editorOption: {
            modules: {
                toolbar: '#toolbar',
            },
            theme: 'snow'
        },
        loading:false,
        user: null,
        users: [],
        types : [
            {value:'CLIENTES', text:"Clientes", type:0},
            {value:'COTIZACIONES', text:"Cotizaciones", type:2},
            {value:'FECHA_PERSONALIZADA', text:"Fecha Personalizada", type:0},
            {value:'PAGOS', text:"Pagos", type:3},
            {value:'PROCESO_DE_CONTRATO', text:"Proceso de contrato", type:3},
            {value:'PROYECTOS', text:"Proyectos", type:0}],
        tokens:[],
        token:null,
        documents:[],
        document:null,
        documentSelected:[],
        options:null
    }),
    props:["url","tokenuser", "alert","v","completeUsers"],    
    created(){
        AlertsService.getTokens(this.url,this.tokenuser)
        .then(result => this.tokens = result)
        .catch(error => console.log(error))

        this.componentKey += 1;
    },
    watch:{
        "alert.tipoEvento"(newValue){
            if(newValue){
                let num = this.types.find(e => e.value == newValue).type
                AlertsService.getDocuments(this.url,this.tokenuser, num)
                .then(result => {
                    this.documents = result.map( e => ({text: e.nombreDocumento, value: e.id, tipo:e.tipo, origen:e.origen, url:e.liga}))
                })
                .catch(error => console.log(error))
            }
        },
        "completeUsers"(newValue){
            this.users = [...newValue]
        }
    },
    methods:{
        contentChange(){
            null    
        },
        addToken(){
            if(!this.alert.contenido){
                this.alert.contenido = this.token
            }else{
                let stringAux = this.alert.contenido
                this.alert.contenido = `${stringAux.substring(0, stringAux.length - 4)} ${this.token}${stringAux.substring(stringAux.length - 4)}`
            }
        },
        deleteDocument(originalIndex){
            this.alert.alertaDocumentos.splice(originalIndex,1)
        },
        alerts(type, message, subtext){
            this.$swal({
            icon: type,
            title: message,
            text: subtext,
            showConfirmButton: false,
            timer: 1700,
            customClass: {
                confirmButton: 'btn btn-primary',
            },
                buttonsStyling: false,
            })
        },
        addDocument(){
            for(var i = 0; i < this.document.length ; i++){
                let incluyed = false
                this.alert.alertaDocumentos.find(file=>{
                    if(file.value == this.document[i].value) incluyed = true
                })
                if(!incluyed) this.alert.alertaDocumentos.push(this.document[i])
            }
            this.document=[]
            this.componentKey += 1;
        }
    },
    computed: {
        table() {
            return {
                cols: [
                    { label: "Nombre", field: 'text' },
                    { label: "Origen", field: 'origen' },
                    { label: "Tipo archivo", field: 'tipo' },
                    { field: 'actions', label: 'Acciones' , sortable:false},
                ],
                rows: this.alert.alertaDocumentos,
                total: this.alert.alertaDocumentos.length,
                pagination: {
                    enabled: true,
                    perPage: 20,
                    perPageDropdown: [20, 50, 100, 1000],
                },
            }
        },
    }
}
</script>
<style>
    .buttonAdd{
        margin-top:4vh;
    }
    .note{
        font-size: x-small;
    }
    #formQuill{
        margin-bottom: 0;
    }
</style>