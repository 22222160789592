import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuecustomElement from 'vue-custom-element'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import Ripple from 'vue-ripple-directive'
import Datetime from 'vue-datetime'
import VueClipboard from 'vue-clipboard2'

import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)

import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import icons from './font-awesome-icons'

import oauth2 from './plugins/oauth2'

while (icons.length) library.add(icons.shift())

Vue.use(Datetime)
Vue.use(VueClipboard)
Vue.config.productionTip = false

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	params: {}
});
Vue.use(vuecustomElement);
Vue.use(VueAxios, instance);


Vue.directive('ripple', Ripple);
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)

// replace vuexy feather icons for font awesome
Vue.component('FeatherIcon', FontAwesomeIcon);

Vue.use(oauth2, {
  store: store,
  store_keys: {
    'scope': 'session/scopes',
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.router = router;
App.router = Vue.router;
App.validations = {}
Vue.customElement('mobysuite-alert-widget', App)

