export default {
  install(Vue, options) {
    // every permission should exists in the scope
    const strict_reducer = (acc, permission) => acc
    && options.store.getters[options.store_keys.scope].indexOf(permission) !== -1

    // almost one permission should be in the scope
    const non_strict_reducer = (acc, permission) => acc
    || options.store.getters[options.store_keys.scope].indexOf(permission) !== -1

    Vue.mixin({
      methods: {
        has_authority(permission) {
          const authorities = permission.split(' ')
          return authorities.reduce(strict_reducer, true)
        },
        has_any_authority(permission) {
          const authorities = permission.split(' ')
          return authorities.reduce(non_strict_reducer, false)
        },
      },
    })
  },
}
