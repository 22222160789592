<template>
    <div>
      <b-row align-h="between" class="row-inicial">
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="reloadAlerts()">
            <font-awesome-icon :icon="['fas', 'redo']" class="mr-1" />
          </b-button>
        </b-col>
        <b-col cols="2.5">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="add()">
            <font-awesome-icon :icon="['far', 'plus']" />
            <span  class="ml-50">Nueva Alerta</span>
          </b-button>
        </b-col>
      </b-row>
      <div>
        <data-table mode="remote" :columns="table.cols" :rows="table.rows" :pagination-options="table.pagination" :totalRows="table.total"
          :isLoading.sync="loading" @on-page-change="on_page_change" id="tabla"
          @on-per-page-change="on_per_page_change" @on-sort-change="on_sort_change">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column && props.column.field == 'actions'">
              <div toggle-class="text-decoration-none" no-caret>
                <b-button size="sm" variant="info" @click="info(props.row)" class="mr-1">
                  <font-awesome-icon class="cursor-pointer" :icon="['fas', 'eye']"/>
                </b-button>
                <b-button size="sm" variant="outline-primary" @click="editItem(props.row)" class="mr-1">
                  <font-awesome-icon :icon="['fal', 'pencil-alt']"/>
                </b-button>
                <b-button size="sm" variant="danger" @click="deleteAlert(props.row)" class="mr-1">
                  <font-awesome-icon :icon="['fal', 'trash']"/>
                </b-button>
                </div>
            </span>
            <span v-else-if="props.column && props.column.field == 'activo'">
              <b-form-checkbox v-model="props.row.activo" name="check-button" class="switch" switch @change="saveActive(props.row)">
              </b-form-checkbox>
            </span>
          </template>
        </data-table>
      </div>
    </div>
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import moment from 'moment'
import AlertsService from '@/services/AlertsService'
export default {
  name: 'DemoItem',
  components:{ 'data-table': DataTable },
  props:{
    url: {default:""},
    tokenuser: {default: ""}
  },
  data: () => ({
    alertas: {
      content: [],
      size: 20,
      number:0
    },
    current_sort:null,
    loading: false,
  }),
  created(){
      this.init_data()
  },
  methods: {
    on_per_page_change(params) {
      window.scrollTo(0,0)
      this.get_page(1, params.currentPerPage, this.current_sort)
    },
    on_page_change(params) {
      window.scrollTo(0,0)
      this.get_page(params.currentPage, this.alertas.size, this.current_sort)
    },
    on_sort_change(params) {
      this.get_page(1, this.alertas.size, params)
    },
    init_data(){
      this.get_page(1, this.alertas.size)
    },
    get_page(page, size, sort){
      this.loading=true
      AlertsService.getAlerts(this.url, this.tokenuser, {page,size,sort})
      .then(res=>{
        this.alertas = res
        this.alertas.content = JSON.parse(res.content)})
      .catch(()=> {
        this.alerts("error", "!Error!", `Hubo un error, intentelo nuevamente`)
        this.alertas.content = []})
      .finally(()=>this.loading = false)
    },
    saveActive(row){
      let textAlert = !row.activo ? 'desactivar' : 'activar'
      let active = row.activo ? 1 : 0
      AlertsService.editState(this.url, this.tokenuser, row.id, active)
      .then(()=>('ok'))
      .catch(()=> this.alerts("error", "!Error!", `No fue posible ${textAlert} ${row.nombreAlerta}`))
      .finally(()=>this.get_page(parseInt(this.alertas.number)+1, this.alertas.size))
    },
    reloadAlerts(){
      this.get_page(1, this.alertas.size)
    },
    info(row) {
      this.$swal({
        title: row.asunto,
        html: row.contenido,
        showConfirmButton:false,
        showCancelButton:false,
        cancelButtonAriaLabel: 'Thumbs down'
      })
    },
    editItem(item){
      this.$emit("openForm", item.id);
    },
    add(){
      this.$emit("openForm");
    },
    alerts(type, message, subtext){
      this.$swal({
        icon: type,
        title: message,
        text: subtext,
        showConfirmButton: false,
        timer: 1700,
        customClass: {
        confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    deleteAlert(row) {
      this.$swal({
        title: "¿Estás seguro de eliminar?",
        text:  `Se eliminará ${row.nombreAlerta}`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: 'Si, Eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          AlertsService.deleteItem(this.url, this.tokenuser, row.id)
          .then(response=> {
            if(response.status == 200){
              this.alerts("success", "!Éxito!", `Se eliminó ${row.nombreAlerta}`)
              this.get_page(parseInt(this.alertas.number)+1, this.alertas.size)
            }else{
              this.alerts("error", "!Error!", `No fue posible eliminar ${row.nombreAlerta}`)
            }
          })
          .catch(() => this.alerts("error", "!Error!", `No fue posible eliminar ${row.nombreAlerta}`))
        }
      });
    },
  },
  computed: {
    table() {
      return {
        cols: [
          { label: "Activo", field: 'activo'},
          { label: "Nombre Alerta", field: 'nombreAlerta' },
          { label: "Proyecto", field: 'alertaProyectos'},
          { label: "Fecha Creación", field: 'fechaCreacion',formatFn: (a) => (a ? moment(a).format('DD-MM-YYYY hh:mm a') : '' )},
          { label: "Usuario Creación", field: 'usuarioCreador' },
          { label: "Fecha Última Modificación", field: 'fechaModificacion',formatFn: (a) => (a ? moment(a).format('DD-MM-YYYY hh:mm a') : '') },
          { label: "Usuario Última Modificación", field: 'nameUsuarioModificacion' },
          { label: 'Acciones',field: 'actions', sortable: false}
        ],
        rows: this.alertas.content,
        total: this.alertas.totalElements,
        pagination: {
          enabled: true,
          perPage: this.alertas.size,
          perPageDropdown: [20, 50, 100, 1000],
        },
      }
    }
  },
}
</script>
<style>
  .row-inicial{
    margin:.5vh;
    padding-left: 0;
    padding-right: 3vh;
  }
  .switch ::before{
    height: 1.2rem !important;
  }
  #tabla {
    font-family:Helvetica,Futura,Arial,Verdana,sans-serif;
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-switch .custom-control-label::before{
    padding: 0.7rem;
  }
  mobysuite-alert-widget .btn-sm{
    padding: 0.25rem 0.25rem  !important;
  }
</style>