<template >
    <div class="wrapperButton" @click="select">
          <b-button class="buttonIcon"  pill >
            <font-awesome-icon :icon="icon" :style="style"></font-awesome-icon>
            <span>{{text}}</span>
        </b-button>
    </div>
</template>

<script>
export default {
    name:'IconButton',
    props:{
        icon:{
            type:Array
        },
        text:{
            type:String
        },
        width:{
            type:String,
            default:'10px'
        },
        height:{
            type:String,
            default:'11px'
        }
    },
    methods:{
        select(){
            this.$emit('select')
        }
    },
    computed:{
        style(){
            const style = {
                width: `${this.width} `,
                height: `${this.height} `
            }
            
            return style
        }

    }
}
</script>

<style lang="less" scoped>
.wrapperButton {
    .buttonIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins';
        font-size: 11px;
        box-shadow: 2px 3px 2px 1px rgb(0 0 0 / 12%);
        border: 1px solid #00718C!important;
        width:99px;
        height: 25px;
        background: white!important;
        svg{
            color:#00718C;
            margin-right: 4px;
        }
        span{
            color:#00718C ;
        }
    }
    
}
</style>
<style lang="less">
.wrapperButton{
    .buttonIcon{
        border: 1px solid yellow;
        background: white;
    }
}
</style>