export default {
  applyDrag: (filteredArray, originalArray, dragResult) => {
    const { oldIndex, newIndex } = dragResult;

    let result = {
      filtered: [...filteredArray],
      original: [...originalArray],
    };

    let originalOldIndex = oldIndex;
    let originalNewIndex = newIndex;

    if (filteredArray.length != originalArray.length) {
      if (newIndex > oldIndex) {
        originalOldIndex = originalArray.findIndex(e => e.label == filteredArray[newIndex].label);
        originalNewIndex = originalArray.findIndex(e => e.label == filteredArray[newIndex - 1].label);
      } else {
        originalOldIndex = originalArray.findIndex(e => e.label == filteredArray[newIndex].label);
        originalNewIndex = originalArray.findIndex(e => e.label == filteredArray[newIndex + 1].label);
      }
    }

    let removed = result.original.splice(originalOldIndex, 1)[0];
    result.original.splice(originalNewIndex, 0, removed);
    return result;
  }
}
